import React, { useState } from "react";
import Footer from "../layout/footer";

// import Navbar2 from "./navbar2";
import Navbar from "./navbar";

const Layout = ({ children }) => {
  const [showWhat, setShowWhat] = useState(false);
  const [showHow, setShowHow] = useState(false);
  const [showProgramme, setShowProgramme] = useState(false);
  const [showPart, setShowPart] = useState(false);

  const handleSubMenu = id => {
    if (id === "What") {
      setShowHow(false);
      setShowPart(false);
      setShowWhat(!showWhat);
    } else if (id === "How") {
      setShowWhat(false);
      setShowPart(false);
      setShowHow(!showHow);
    } else if (id === "Participate") {
      setShowWhat(false);
      setShowHow(false);
      setShowPart(!showPart);
    }
  };
  // const handleSubMenu = id => {
  //   if (id === "About") {
  //     setShowHow(false);
  //     setShowProgramme(false);
  //     setShowPart(false);
  //     setShowWhat(!showWhat);
  //   } else if (id === "Fundraising") {
  //     setShowWhat(false);
  //     setShowProgramme(false);
  //     setShowPart(false);
  //     setShowHow(!showHow);
  //   } else if (id === "Programmes") {
  //     setShowWhat(false);
  //     setShowProgramme(!showProgramme);
  //     setShowPart(false);
  //     setShowHow(!showHow);
  //   } else if (id === "Participate") {
  //     setShowWhat(false);
  //     setShowHow(false);
  //     setShowProgramme(false);
  //     setShowPart(!showPart);
  //   }
  // };

  const handleSubMenuClose = () => {
    setShowWhat(false);
    setShowHow(false);
    setShowPart(false);
    setShowProgramme(false);
  };

  return (
    <div className="flex flex-col justify-between h-full ">
      <Navbar
        showWhat={showWhat}
        showHow={showHow}
        showPart={showPart}
        handleSubMenu={handleSubMenu}
        onClick={handleSubMenuClose}
        role="button"
        tabIndex={0}
        onKeyDown={handleSubMenuClose}
      />

      {/* <Navbar2
        showWhat={showWhat}
        showHow={showHow}
        showProgramme={showProgramme}
        showPart={showPart}
        handleSubMenu={handleSubMenu}
        role="handlemenu"
      /> */}

      <div
        className="relative min-h-screen px-5 mt-12 md:space-y-24  space-y-12"
        onClick={handleSubMenuClose}
        role="none"
        tabIndex={0}
        onKeyDown={handleSubMenuClose}
      >
        {children}
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
