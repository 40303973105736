import React from "react";
import Layout from "../../components/layout/layout";

import { StaticImage } from "gatsby-plugin-image";

import SEO from "../../components/seo";

const fawa = () => {
  return (
    <>
      <div className="absolute w-full h-32 z-n10 bg-gradient-to-r from-swa-1 to-swa-5"></div>
      <Layout>
        <SEO
          title="Federation of Asia-Pacific Women's Association"
          description="The Federation of Asian Women’s Association was first conceived in 1952 to undertake the unification of Asian women for the progress of Asia and the protection of the ideals of freedom and justice, contributing to world effort to building lasting peace."
        />
        <section className="max-w-4xl px-2 mx-auto sm:w-4/5">
          <div className="relative pb-16 overflow-hidden ">
            <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
              <div
                className="relative h-full mx-auto text-lg max-w-prose"
                aria-hidden="true"
              >
                <svg
                  className="absolute transform translate-x-32 top-12 left-full"
                  width={404}
                  height={384}
                  fill="none"
                  viewBox="0 0 404 384"
                >
                  <defs>
                    <pattern
                      id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x={0}
                        y={0}
                        width={4}
                        height={4}
                        className="text-gray-200"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width={404}
                    height={384}
                    fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
                  />
                </svg>
                <svg
                  className="absolute transform -translate-x-32 -translate-y-1/2 top-1/2 right-full"
                  width={404}
                  height={384}
                  fill="none"
                  viewBox="0 0 404 384"
                >
                  <defs>
                    <pattern
                      id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x={0}
                        y={0}
                        width={4}
                        height={4}
                        className="text-gray-200"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width={404}
                    height={384}
                    fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
                  />
                </svg>
                <svg
                  className="absolute transform translate-x-32 bottom-12 left-full"
                  width={404}
                  height={384}
                  fill="none"
                  viewBox="0 0 404 384"
                >
                  <defs>
                    <pattern
                      id="d3eb07ae-5182-43e6-857d-35c643af9034"
                      x={0}
                      y={0}
                      width={20}
                      height={20}
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x={0}
                        y={0}
                        width={4}
                        height={4}
                        className="text-gray-200"
                        fill="currentColor"
                      />
                    </pattern>
                  </defs>
                  <rect
                    width={404}
                    height={384}
                    fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
                  />
                </svg>
              </div>
            </div>

            <div className="relative px-4 sm:px-6 lg:px-8">
              <div className="mx-auto text-lg max-w-prose">
                <h1>
                  <span className="block mt-2 text-3xl font-extrabold leading-8 tracking-tight text-center text-swa-1 sm:text-4xl">
                    Federation of Asia-Pacific Women’s Association (FAWA)
                  </span>
                </h1>

                <div className="my-2 border border-swa-4 aspect-w-16 aspect-h-9">
                  <iframe
                    src={`https://www.youtube.com/embed/U4FN5svwP7I`}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="Embedded youtube"
                    className="w-full p-2"
                  />
                </div>
                <p className="mt-8 text-xl leading-8 text-gray-500">
                  SWA hosted the biennial 22nd FAWA Convention in Singapore in
                  2016. FAWA conventions were held here in 2004 and 1986. The
                  23rd FAWA Convention was held in Sep 2018 in Manila,
                  Philippines. The 24th FAWA Convention will be held in Sep 2023 in Tokyo, Japan.
                  <br />
                  <br />
                  The Federation of Asian Women’s Association was first
                  conceived in 1952 by the late Geronima T. Pecson, one of the
                  noted figures of the National Federation of Women’s Club
                  (NFWC) of the Philippines. On June 19, 1958, she wrote a
                  letter to Minerva G. Laudico, then the President of NFWC,
                  urging the NFWC to undertake the unification of Asian women
                  for the progress of Asia and the protection of the ideals of
                  freedom and justice, contributing to world effort to building
                  lasting peace.
                  <br />
                  <br />
                  Fully aware of the dangerous tension then building up in Asia,
                  Mineva, with the unanimous support of the NFWC Board,
                  undertook the organization of the initial convention. On
                  February 23, 1959, 33 women groups in 15 countries were sent
                  letters inviting Asian women leaders to meet coinciding with
                  the 68th Convention of GFWC (General Federation of Women’s
                  Club), which was to be held in Manila from June 20 to 26,
                  1959. In her letter, she proposed the organization of the
                  Federation of Asian Women‘s Clubs “so as to affect not only
                  closer relations but also more effective planning and
                  execution of programmes for social welfare.”
                  <br />
                  <br />
                  With her second letter on May 19, 1959, Mrs Laudico sent with
                  her invitation, a draft list of objectives, a suggested
                  structure, a draft program of action and a draft constitution.
                  <br />
                  <br />
                  The Meeting of the women leaders began formally on June 17, at
                  9.00 am at the Little Theatre of the Arellano University. With
                  the documents for discussions sent in advance, and considering
                  the capacity of the participants, the decision to organize the
                  Federation of Asian Women’s Associations was decided in no
                  time. The constitution was approved and the following were
                  declared the Chartered Members:
                </p>
                <ul className="mx-auto my-3 mt-6 text-xl leading-8 text-gray-500 ">
                  <li>
                    Myanmar (Burma) - Union of Burma Council of Women's
                    Association
                  </li>
                  <li>Guam - Guam Women's Club</li>
                  <li>Laos - Laos Women's Club</li>
                  <li>
                    Singapore - Singapore Women's Association (then known as
                    Singapore Federation of Girls' Clubs)
                  </li>
                  <li>
                    The Republic of China - Taipei International Women's Club,
                    Chinese Women Anti-Aggression League and YWCA{" "}
                  </li>
                  <li>Thailand - Thai Culture Women's Club</li>
                  <li>Vietnam - International Women's Association</li>
                  <li>Philippines - National Federation of Women's Clubs</li>
                </ul>
                <p className="mt-8 text-xl leading-8 text-gray-500">
                  The Delegates of India, Japan and Korea joined the meeting
                  later.
                  <br />
                  <br />
                  The founding members of FAWA were highly academically
                  qualified political association leaders. What they
                  accomplished during two days is a landmark in Asian history.
                  FAWA was organized to effect major changes in the status of
                  women. The aim was to create opportunities for women to get
                  together and discuss problems, which affect Asian countries
                  and communities and to try and find solutions to these
                  problems. It was also to provide an opportunity for women to
                  be exposed to and to better understand the various Asian
                  cultures, traditions and ways of life that are different from
                  their own.
                  <br />
                  <br />
                  The holding of periodic conventions and meetings in different
                  member countries was decided for the purpose of strengthening
                  mutual understanding and cooperation, rendering periodic
                  reports, evaluating and improving and updating action
                  programmes. The themes and proceedings of each convention
                  reflect the issues of the times.
                </p>
              </div>
            </div>
            <section className="mx-1 mt-10 text-xl font-light border-black text-swa-2">
              <div className="grid grid-cols-2 my-5 gap-x-1 gap-y-1 sm:grid-cols-3 sm:gap-x-1 lg:grid-cols-4 xl:gap-x-1">
                <div className="block w-full overflow-hidden rounded-lg group ">
                  <StaticImage
                    src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1636946148/fawaPage/obrdn7e5mtl5ep3er2c6.jpg"
                    alt="fawa"
                    aspectRatio={10 / 7}
                    objectPosition={"50% 0%"}
                  />
                </div>
                <div className="block w-full overflow-hidden rounded-lg group ">
                  <StaticImage
                    src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1636946154/fawaPage/cixfdsnvmkdrv7ztcana.jpg"
                    alt="fawa"
                    aspectRatio={10 / 7}
                    objectPosition={"50% 0%"}
                  />
                </div>
                <div className="block w-full overflow-hidden rounded-lg group ">
                  <StaticImage
                    src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1636946161/fawaPage/eubvqtpxyap937hs9cn8.jpg"
                    alt="fawa"
                    aspectRatio={10 / 7}
                    objectPosition={"50% 0%"}
                  />
                </div>
                <div className="block w-full overflow-hidden rounded-lg group ">
                  <StaticImage
                    src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1636946165/fawaPage/d8yq08llhlfdmktxkuig.jpg"
                    alt="fawa"
                    aspectRatio={10 / 7}
                    objectPosition={"50% 50%"}
                  />
                </div>
                <div className="block w-full overflow-hidden rounded-lg group ">
                  <StaticImage
                    src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1636946353/fawaPage/fxeypb6pa3rayerle5pq.jpg"
                    alt="fawa"
                    aspectRatio={10 / 7}
                    objectPosition={"50% 0%"}
                  />
                </div>
                <div className="block w-full overflow-hidden rounded-lg group ">
                  <StaticImage
                    src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1636946354/fawaPage/swxartow0kv2lzfxnh51.jpg"
                    alt="fawa"
                    aspectRatio={10 / 7}
                    objectPosition={"50% 0%"}
                  />
                </div>
                <div className="block w-full overflow-hidden rounded-lg group ">
                  <StaticImage
                    src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1636946354/fawaPage/ugpn4r5vxuflwcwfbuza.jpg"
                    alt="fawa"
                    aspectRatio={10 / 7}
                    objectPosition={"50% 50%"}
                  />
                </div>
                <div className="block w-full overflow-hidden rounded-lg group ">
                  <StaticImage
                    src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1636946355/fawaPage/pucyytmyinvbljb2hhza.jpg"
                    alt="Singapore Vision Care Programme"
                    aspectRatio={10 / 7}
                    objectPosition={"50% 0%"}
                  />
                </div>
              </div>
            </section>
          </div>
        </section>
      </Layout>
    </>
  );
};

export default fawa;
